import { makeTheme } from '@theme-ui/css/utils'
import cursor from './images/cursor.png'

const heading = {
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
}

const fixViewport = {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden'
}

export const base = makeTheme({
    space: [0, 4, 8, 16, 20, 26, 32, 48, 64, 128, 256, 512],
    fonts: {
        body: '"greycliff-cf", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        heading: 'Righteous',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 50, 64, 96],
    fontWeights: {
        body: 300,
        heading: 500,
        bold: 400,
        extraBold: 600,
    },
    breakpoints: ['544px', '768px', '1012px', '1280px', '1441px'],
    radii: [5, 10, 15, 30],
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    links: {
        bold: {
            fontWeight: 'bold',
        },
        nav: {
            fontWeight: '600',
            color: 'purple',
            textDecoration: 'none',
        }
    },
    buttons: {
        primary: {
            color: 'white',
            bg: 'red',
            borderRadius: ['5px'],
            px: '20px',
            py: '10px',
            fontFamily: 'body',
            fontSize: [3],
            fontWeight: '700',
            height: ['40px', '40px', '46px'],
            lineHeight: ['40px', '40px', '46px'],
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            '&&:hover': {
                bg: 'transparent',
                color: 'primary'
            },

            '&&:disabled': {
                bg: 'disabled',
            },
        },

        outlinePurple: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'purple',
            bg: 'transparent',
            borderStyle: 'solid',
            borderWidth: '3px',
            borderColor: 'purple',
            borderRadius: '10px',
            px: '25px',
            py: '10px',
            fontFamily: 'body',
            fontSize: [4],
            fontWeight: '700',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',

            '&&:hover': {
                bg: 'purple',
                color: 'yellow'
            },
        },

        outlinePurpleBlue: {
            variant: 'buttons.outlinePurple',
            bg: 'blue',
        },

        outlinePurpleRed: {
            variant: 'buttons.outlinePurple',
            bg: 'red',
        },

        outlinePurpleYellow: {
            variant: 'buttons.outlinePurple',
            bg: 'yellow',
        },

        outlinePurpleGreen: {
            variant: 'buttons.outlinePurple',
            bg: 'green',
        },

        outlineWhite: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            bg: 'transparent',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: 'white',
            borderRadius: '5px',
            px: '25px',
            py: '10px',
            fontFamily: 'body',
            fontSize: [3],
            fontWeight: '700',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',

            '&&:hover': {
                bg: 'white',
                color: 'purple'
            },
        }
    },

    config: {
        initialColorModeName: 'light',
    },

    colors: {
        black: '#000',
        white: '#f1f1f1',
        purple: '#2E0B5F',
        blue: '#5CE6F5',
        green: '#6DDA5E',
        yellow: '#F1E15A',
        red: '#FF6875',
        red50: '#FFB3BA'
    },
    styles: {

        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            background: 'radial-gradient(circle, rgba(16,6,48,1) 0%, rgba(17,12,1,1) 100%);',
            color: '#ffffff',
            cursor: `url(${cursor}), auto`
            // colorScheme: 'only light'
        },

        h1: {
            ...heading,
            fontSize: 5,
        },
        h2: {
            ...heading,
            fontSize: 4,
        },
        h3: {
            ...heading,
            fontSize: 3,
        },
        h4: {
            ...heading,
            fontSize: 2,
        },
        h5: {
            ...heading,
            fontSize: 1,
        },
        h6: {
            ...heading,
            fontSize: 0,
        },
        p: {
            color: 'text',
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        },
        a: {
            color: 'white',
        },
        pre: {
            fontFamily: 'monospace',
            overflowX: 'auto',
            code: {
                color: 'inherit',
            },
        },
        code: {
            fontFamily: 'monospace',
            fontSize: 'inherit',
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
        },
        th: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        td: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        img: {
            maxWidth: '100%',
        },
    },
})

export default base
