import { Box, Text, Link, Button, Flex, Image, NavLink } from 'theme-ui';
import base from '../../theme';
import handCursor from './../../images/hand-cursor.png'
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import React, { useEffect } from 'react';
import crap from './../../images/rode-kreeft-animated.gif'

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default function Footer(props) {

    useEffect(() => {

        const timeline = gsap.timeline();

        const controller = new ScrollMagic.Controller();

        timeline.to(
            '.js-crap-footer',
            {
                x: 250,
                duration: 0.3,
                delay: 0.1
            }
        );

        timeline.to(
            '.js-crap-footer-img',
            {
                x: 250,
                duration: 0.5
            }, '-=0.3'
        );

        new ScrollMagic.Scene({
            triggerElement: '.js-footer',
            triggerHook: 'onEnter',
            duration: '100%'
        }).setTween(timeline).addTo(controller);

    }, []);

    return (
        <>
            <Box className='js-footer c-footer' sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '10vh 0 15vh',
                overflow: 'hidden',
                position: 'relative',
                background: base.colors.yellow,
                textAlign: 'center',
                color: base.colors.purple,
                fontSize: '2rem',
                fontWeight: 'bold',
                position: 'relative'
            }}>

                <Flex sx={{ justifyContent: 'center' }}>
                    <Link href="https://mixedemotionslive.nl/tickets" sx={{
                        textDecoration: 'none'
                    }}>
                        <Button sx={{ cursor: `url(${handCursor}), auto`, display: 'flex' }} variant='outlinePurple'>
                            <span>Scoor je tickets</span>
                        </Button>
                    </Link>
                </Flex>

                <Box className={'js-crap-footer'} style={{
                    width: '475px',
                    height: '241px',
                    position: 'absolute',
                    left: '50%',
                    transformOrigin: '50% 50%',
                    top: '50%',
                    zIndex: 1
                }} >
                    <Image className={'js-crap-footer-img'} src={crap} sx={{
                        transform: 'translate(-28%,-66%)'
                    }} />
                </Box>

            </Box>
            <Box sx={{
                background: base.colors.yellow,
            }}>

                <Flex as="nav" sx={{
                    justifyContent: 'center',
                    color: 'purple'
                }}>
                    <NavLink href="https://mixedemotionslive.nl" p={2}>
                        Copyright 2022 © Mixed Emotions Live
                    </NavLink>

                </Flex>

            </Box>
        </>
    );
}
