import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import base from '../../theme';
import { Image, Text } from 'theme-ui';
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import SupaWave from '../../components/base/SupaWave/SupaWave';
import Dots from './SupaDots/SupaDots';
import handCursor from './../../images/hand-cursor.png';
import arcoGnocchi from './../../images/speakers/Arco-Gnocchi.png';
import nienkeVanDeStreek
    from './../../images/speakers/Nienke-van-de-Streek.png';
import dorkasKoenen from './../../images/speakers/Dorkas-Koenen.png';
import charlotteVermeer from './../../images/speakers/Charlotte-Vermeer.png';
import marijnVanDerLaan from './../../images/speakers/Marijn-van-der-Laan.png';

import arrowRight from './../../images/arrow-right.svg';

import Crap from './Crap';
import Fish from './Fish';

ScrollMagicPluginGsap(ScrollMagic, gsap);

export const speakersMap = {
    1: {
        title: 'Wat maakt podcasts zo intiem?',
        image: arcoGnocchi,
        text: 'Arco Gnocchi van Dag en Nacht Media',
        link: 'https://mixedemotionslive.nl/line-up/arco-gnocchi',
        bg: base.colors.yellow,
        bigger: true,
        flip: true
    },
    2: {
        title: 'Hoe vecht Aldi zich terug in de competitieve markt van food retail?',
        image: nienkeVanDeStreek,
        text: 'Nienke van de Streek Director Marketing & Communicatie bij ALDI.',
        link: 'https://mixedemotionslive.nl/line-up/nienke-van-streek',
        bg: base.colors.blue,
        bigger: true,
        flip: false,
        buttonStyle: 'outlinePurpleRed'
    },
    3: {
        title: 'Hoe krijgt Rabobank Nederlanders zo ver dat ze gaan praten over geld?',
        image: dorkasKoenen,
        text: 'Dorkas Koenen is CMO van Rabobank',
        link: 'https://mixedemotionslive.nl/line-up/dorkas-koenen',
        bg: base.colors.green,
        bigger: false,
        flip: true
    },
    4: {
        title: 'Hoe maak je branded content interessant voor de lezeressen van LINDA.?',
        image: charlotteVermeer,
        text: 'Charlotte Vermeer is Creative Lead Branded Content bij LINDA.',
        link: 'https://mixedemotionslive.nl/line-up/charlotte-vermeer',
        bg: base.colors.yellow,
        bigger: true,
        flip: false,
        buttonStyle: 'outlinePurpleBlue'
    },
    5: {
        title: 'NO USE',
        image: marijnVanDerLaan,
        text: 'Marijn van der Laan van Design for Disability',
        link: 'https://mixedemotionslive.nl/line-up/martijn-van-der-laar',
        bg: base.colors.red,
        bigger: false,
        flip: true
    },
};

export default function Speaker(props) {

    const [initilazed, setInit] = useState(false)

    useEffect(() => {

        console.log(initilazed);


        const timeline = gsap.timeline();
        const controller = new ScrollMagic.Controller();

        timeline.fromTo(
            '.js-speaker-' + props.speaker + ' .js-speaker-question',
            {
                y: '-35vh',
                opacity: 0,
                scale: 0.3,
            },
            {
                y: '-35vh',
                opacity: 1,
                ease: 'Power2.easeOut',
                scale: 1
            },
        );
        timeline.to(
            '.js-speaker-' + props.speaker + ' .js-speaker-question',
            {
                y: '0%',
                ease: 'expo',
                scale: 0.8,
                delay: 0.5
            },
        );
        timeline.fromTo('.js-speaker-' + props.speaker + ' .js-speaker-image',
            {
                x: '101vw',
            },
            {
                x: '0%',
                ease: 'expo'
            }
            , '-=0.4');

        timeline.fromTo('.js-speaker-' + props.speaker + ' .js-speaker-arrow',
            {
                opacity: '0',
                transform: 'scale(0.2)',
            },
            {
                ease: 'expo',
                opacity: '100%',
                delay: '10%',
                transform: 'scale(1)'
            }, '-=0.3',
        );

        new ScrollMagic.Scene({
            triggerElement: '.js-speaker-' + props.speaker,
            triggerHook: 'onLeave',
            duration: '70%',
        }).setPin('.js-speaker-' + props.speaker).addTo(controller);

        new ScrollMagic.Scene({
            triggerElement: '.js-speaker-' + props.speaker,
            triggerHook: 0.3,
            duration: '100%',
        }).setTween(timeline).addTo(controller);

        if (Object.keys(speakersMap).length == initilazed) {
            setInit(true)
        }

    }, [initilazed]);

    return (
        <>
            <div
                className={`js-speaker-${props.speaker}`} style={{
                    height: '100vh',
                    position: 'relative',
                    width: '100%',
                    padding: '0 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'none',
                    background: speakersMap[props.speaker].bg,
                }}>
                {props.visibilityOldBrowser && (
                    <SupaWave bg={props.nextBackground} speed={0.4} points={3} />
                )}

                {props.speaker === 2 || props.speaker === 4 ? (
                    <Crap speaker={props.speaker} />
                ) : (
                    <Fish speaker={props.speaker} />
                )}

                <Dots speaker={props.speaker} flip={speakersMap[props.speaker].flip} bigger={speakersMap[props.speaker].bigger} />

                <div className={'js-speaker-image'} style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '800px',
                    justifyContent: 'flex-end',
                }}>
                    <div style={{
                        position: 'relative',
                        width: '100%',
                    }}>
                        <Image sx={{
                            width: '100%',
                            maxHeight: ['300px', '300px', '350px', '350px', '300px', '400px'],
                            objectFit: 'cover',
                        }} src={speakersMap[props.speaker].image} />
                        <Text
                            sx={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '10px',
                                color: 'white',
                                fontWeight: '600',
                            }}>{speakersMap[props.speaker].text}
                        </Text>
                    </div>
                </div>

                <div className={'js-speaker-question'} style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                    maxWidth: '800px',
                    fontSize: 'clamp(1.5rem, 7vw, 2.7rem)',
                    justifyContent: 'flex-start',
                    transformOrigin: '0 0',
                }}>
                    <h2 style={{
                        fontStyle: ['60px', '70px'],
                        lineHeight: '100%',
                        fontWeight: 'bold',
                        color: `${base.colors.purple}`,
                    }}>
                        {props.speaker === 5 ? (
                            <>
                                Erva ard einvl oedv an <u>dyslectie</u> enand e rebepe rking enop jou wbo ods chap
                            </>

                        ) : (
                            <>
                                {speakersMap[props.speaker].title}
                            </>
                        )}
                    </h2>
                </div>
                <div className={'js-speaker-arrow'} style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '800px',
                    justifyContent: 'flex-start',
                }}>
                    <a href={speakersMap[props.speaker].link} style={{
                        display: 'flex',
                        marginTop: '20px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 'clamp(1rem, 3.5vw, 2rem)',
                        color: `${base.colors.purple}`,
                        background: speakersMap[props.speaker].bg,
                        padding: '20px',
                        borderRadius: '10px',
                        alignSelf: 'flex-end',
                        cursor: `url(${handCursor}), auto`
                    }}>
                        <span style={{
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                        }}>Lees het hier</span>
                        <Image style={{
                            marginLeft: '10px',
                            width: '30px',
                            height: '30px',
                        }} src={arrowRight} />
                    </a>
                </div>
            </div>
        </>
    );
}
