import { Box, Flex, Image } from "theme-ui"
import './orientation.css'
import logo from './../../../images/logo-melive.svg';

export default function Orientation({ children }) {

    return (
        <>
            <div className='c-portrait'>
                {children}
            </div>
            <div className='c-landscape'>
                <Flex sx={{
                    justifyContent: 'center',
                    alignItems: 'center'
                    // position: 'relative'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        fontSize: [20, 24],
                        mt: '1.4rem',
                        width: '300px',
                        fontWeight: 600
                    }}>
                        <Image sx={{
                            transform: 'scale(0.4)'
                        }} src={logo} />
                        Draai je scherm of maak je weergave groter 🤩
                    </Box>
                </Flex>
            </div>
        </>
    )
}