import { useEffect } from 'react';
import base from '../../theme';
import { Box, Image, Text } from 'theme-ui';
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

import fish from './../../images/vis-animated.gif'

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default function Flying(props) {
    useEffect(() => {

        const timeline = gsap.timeline();
        const controller = new ScrollMagic.Controller();

        timeline.fromTo(
            '.js-fish',
            {
                left: '0',
                top: '20vh',
                x: '-100%',
                y: '-100%'
            },
            {
                left: '100vw',
                top: '80vh',
                x: '0',
                y: '0',
                duration: 1,
                ease: 'Power2.easeOut'
            },
        );

        new ScrollMagic.Scene({
            triggerElement: '.js-speaker-' + props.speaker,
            triggerHook: 'onCenter',
            duration: '100%'
        }).setTween(timeline).addTo(controller);
    });

    return (
        <>
            <Box className={'js-fish'} sx={{
                width: ['438px', '438px', '657px'],
                height: ['284px', '284px', '426px'],
                position: 'fixed',
                transform: 'rotate(30deg)',
                left: 0,
                top: 0,
                zIndex: 1
            }}>


                <Image style={{
                    width: '100%',
                    height: '100%'
                }} src={fish} />
            </Box>
        </>
    );
}
