import { Box } from 'theme-ui';
import Wave from 'react-wavify';
import { useState } from 'react';

export default function SupaWave(props) {

    const [wild, setWild] = useState(false);

    return (
        <div className='c-wave' onClick={() => setWild(!wild)} style={{
            position: 'absolute',
            left: 0,
            bottom: props.bottom ? props.bottom : '-3vh',
            width: '100%',
            color: props.bg
        }}>
            <Wave fill='currentColor'
                paused={false}
                options={{
                    height: 40,
                    amplitude: 30,
                    speed: wild ? 2 : 0.5,
                    points: wild ? 10 : 2
                }}
            />

            {props.extra && (
                <div style={{
                    background: props.bg,
                    height: '90px',
                    marginTop: '-15px'
                }}></div>
            )}
        </div>
    );
}
