import { useEffect, useState } from 'react';
import base from '../../theme';
import Header from '../../components/base/Header';
import Footer from '../../components/base/Footer';
import Speaker from '../../components/base/Speaker';
import { speakersMap } from '../../components/base/Speaker';
import { useReward } from 'react-rewards';
import SupaWave from '../../components/base/SupaWave/SupaWave';
import Orientation from '../../components/base/Orientation/Orientation';

function App() {

    const [visibilityOldBrowser, setVisibilityOldBrowser] = useState(true)

    // States
    const [mouse, setMouse] = useState({
        x: 0,
        y: 0
    });

    // Rewards
    const { reward, isAnimating } = useReward('rewardId', 'confetti');

    // Click everywhere handler
    useEffect(() => {
        document.addEventListener('click', handleClickEverywhere, true);
        window.addEventListener('resize', handleResize)

        function handleResize() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        handleResize()

        // Fack Samsing Browser I'll delete the wave on that crap
        function detectBrowser() {

            if (navigator.userAgent.match(/samsungbrowser/i)) {
                setVisibilityOldBrowser(false)
            }
        }

        detectBrowser()

    }, []);


    const handleClickEverywhere = (e) => {
        setMouse({
            x: e.clientX,
            y: e.clientY
        })
        reward()
    }

    // Group all speakers and add to <Speaker/>
    let speakers = [];
    const length = 6;
    for (let i = 1; i < length; i++) {

        let background = base.colors.yellow;
        let nextBackground = base.colors.yellow;

        if (i > 1) {
            background = speakersMap[i - 1].bg;
        }

        if (i < length - 1) {
            nextBackground = speakersMap[i + 1].bg;
        }

        speakers.push(
            <div key={`speaker-${i}`} style={{
                heigth: '80vh',
                width: '100%',
                overflow: 'hidden',
                background: background,
            }}>
                <Speaker speaker={i} nextBackground={nextBackground} visibilityOldBrowser={visibilityOldBrowser} />
            </div>,
        );
    }

    return (
        <>
            <Orientation>
                <div id='rewardId' style={{
                    position: 'fixed',
                    left: mouse.x,
                    top: mouse.y,
                    zIndex: 200
                }}></div>
                <Header />
                {visibilityOldBrowser && (
                    <SupaWave bg={base.colors.yellow} bottom='-10vh' extra={true} />
                )}
                {speakers}
                <Footer />
            </Orientation>
        </>
    );
}

export default App;