import { useEffect } from 'react';
import base from '../../theme';
import { Image, Link, Button, Box, Flex } from 'theme-ui';
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import handCursor from './../../images/hand-cursor.png'


import crap from './../../images/rode-kreeft-animated-rope.gif'

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default function Crap(props) {
    useEffect(() => {

        const timeline = gsap.timeline();

        const controller = new ScrollMagic.Controller();

        timeline.fromTo(
            '.js-crap',
            {
                left: '0',
                top: '80vh',
                x: '-100%',
                y: '0',
            },
            {

                left: 'calc(100vw + 200px)',
                top: '20vh',
                x: '0',
                y: '-100%',
                duration: 2,
                ease: 'Power2.easeOut'
            },
        );

        new ScrollMagic.Scene({
            triggerElement: '.js-speaker-' + props.speaker,
            triggerHook: 'onCenter',
            duration: '100%'
        }).setTween(timeline).addTo(controller);
    });

    return (
        <>
            <Box className={'js-crap'} style={{
                position: 'fixed',
                transform: 'rotate(-20deg)',
                left: 0,
                top: 0,
                zIndex: 1
            }} >

                <Link href="https://mixedemotionslive.nl/tickets" sx={{
                    textDecoration: 'none',
                    position: 'absolute',
                    left: 0,
                    top: '115px',
                    transform: 'translateX(-100%)'

                }}>
                    <Button sx={{ cursor: `url(${handCursor}), auto`, display: 'flex' }} variant='outlinePurpleRed'>
                        <span>Scoor je tickets</span>
                    </Button>
                </Link>
                <Image sx={{
                    width: ['537px'],
                    height: ['241px'],
                    minWidth: ['537px'],
                    maxWidth: ['537px']
                }} src={crap} />
            </Box>
        </>
    );
}
