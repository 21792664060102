import { useEffect } from 'react';
import base from '../../../theme';
import { Box, Image, Text } from 'theme-ui';
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { PropTween } from 'gsap/gsap-core';

export default function Dots(props) {

    const size = 25
    const spacing = 1.4
    const offset = size * spacing

    const baseDotsStyle = {
        position: 'absolute',
        height: size,
        width: size,
        background: props.color ? props.color : base.colors.purple,
        borderRadius: '50%'
    }

    useEffect(() => {

        if (!props.disableAnimation) {

            const timeline = gsap.timeline();
            const controller = new ScrollMagic.Controller();

            timeline.fromTo(
                '.js-dot-' + props.speaker,
                {
                    opacity: 0.9,
                    scale: 0.15,
                },
                {
                    opacity: 1,
                    scale: 1,
                    delay: 3.5,
                    stagger: {
                        each: 0.15
                    }
                },

            );

            new ScrollMagic.Scene({
                triggerElement: '.js-speaker-' + props.speaker,
                triggerHook: 'onCenter',
                duration: '100%'
            }).setTween(timeline).addTo(controller);

        }
    });


    return (

        <Box className={'js-dots-' + props.speaker} sx={{
            position: props.fixed ? 'fixed' : 'absolute',
            left: props.flip ? null : -(size / 3),
            right: props.flip ? -(size / 3) : null,
            transform: props.flip ?
                ['scale(0.5) rotate(180deg)',
                    'scale(0.5) rotate(180deg)',
                    'scale(1) rotate(180deg)'] :
                ['scale(0.5)',
                    'scale(0.5)',
                    'scale(1)'],
            top: props.flip ? props.bottom ? null : '40vh' : '5vh',
            bottom: props.bottom ? '3vh' : null,
            zIndex: 1000
        }}>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: 0 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset, top: offset }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 2, top: offset * 2 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 3, top: offset * 3 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 4, top: offset * 4 }}></Box>
            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 5, top: offset * 5 }}></Box>
            )}

            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: offset * 2 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset, top: offset * 3 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 2, top: offset * 4 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 3, top: offset * 5 }}></Box>
            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 4, top: offset * 6 }}></Box>
            )}

            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: offset * 4 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset, top: offset * 5 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 2, top: offset * 6 }}></Box>
            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 3, top: offset * 7 }}></Box>
            )}

            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: offset * 6 }}></Box>
            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset, top: offset * 7 }}></Box>
            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset * 2, top: offset * 8 }}></Box>
            )}

            <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: offset * 8 }}></Box>
            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: offset, top: offset * 9 }}></Box>
            )}

            {props.bigger && (
                <Box className={'js-dot-' + props.speaker} sx={{ ...baseDotsStyle, left: 0, top: offset * 10 }}></Box>
            )}

        </Box>

    )
}