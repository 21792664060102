import { useEffect, useRef } from 'react';
import { Box, Image, Text, Link, Button } from 'theme-ui';
import base from '../../theme';

import gsap from 'gsap';

import afterMovie from './../../images/aftermovie-2019-mixed-emotions-live.mp4';
import logo from './../../images/logo-melive.svg';
import React from 'react';

import yellowWave from '../../images/yellow-wave.svg';

import cursor from '../../images/cursor.png';

import Crap from './Crap';
import Fish from './Fish';

import Dots from './SupaDots/SupaDots';

export default function Header(props) {

    const el = useRef();
    const q = gsap.utils.selector(el);
    const tl = useRef();

    useEffect(() => {    // Update the document title using the browser API

        const timeline = gsap.timeline();


        timeline.fromTo(
            q(".js-emotion-presents"),
            {
                opacity: 0,
                scale: 0.3,
            },
            {
                opacity: 1,
                ease: 'Power2.easeOut',
                duration: 0.4,
                delay: 0.3,
                scale: 1
            }, '-=0.4'
        );
        timeline.fromTo(
            q(".js-mixed-logo"),
            {
                opacity: 0,
                scale: 0.3,
                rotate: '-40deg'
            },
            {
                opacity: 1,
                ease: 'Elastic.easeOut',
                duration: 1.3,
                scale: 1,
                rotate: '0'
            }, '-=0.1'
        );
        timeline.fromTo(
            q(".js-mixed-date"),
            {
                opacity: 0,
                scale: 0.3,
                rotate: '20deg'
            },
            {
                opacity: 1,
                ease: 'Back.easeOut',
                duration: 0.4,
                scale: 1,
                rotate: '0'
            }, '-=0.7'
        );
        timeline.fromTo(
            q('.js-dots-left'),
            {
                opacity: 0,
                x: '-20px',
            },
            {
                opacity: 1,
                duration: 0.5,
                x: '0'
            }, '-=0.2'

        );
        timeline.fromTo(
            q('.js-dots-right'),
            {
                opacity: 0,
                x: '20px',
            },
            {
                opacity: 1,
                duration: 0.5,
                x: '0'
            }, '-=0.2'

        );
        timeline.fromTo(
            q('.js-dot-right'),
            {
                opacity: 0.9,
                scale: 0.15,
            },
            {
                opacity: 1,
                scale: 1,
                stagger: {
                    each: 0.05
                }
            }, '-=0.7'

        );
        timeline.fromTo(
            q('.js-dot-left'),
            {
                opacity: 0.9,
                scale: 0.15,
            },
            {
                opacity: 1,
                scale: 1,
                stagger: {
                    each: 0.05
                }
            }, '-=0.7'
        );

    });

    return (
        <Box>
            <Box ref={el} sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'calc(var(--vh) * 100)',
                overflow: 'hidden',
                position: 'relative',
                zIndex: '-1'
            }}>
                <Dots speaker={'left'} flip={false} bigger={true} disableAnimation={true} color='white' fixed={true} />
                <Dots speaker={'right'} flip={true} bigger={false} disableAnimation={true} color='white' fixed={true} bottom={true} />
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    backgroundColor: base.colors.purple,
                    opacity: '80%',
                    zIndex: '10',
                }}>
                </div>
                <video autoPlay="autoplay" playsInline="" muted="muted"
                    loop="loop"
                    style={{
                        minHeight: '100%',
                        minWidth: '100%',
                        width: 'auto',
                        height: 'auto',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '0',
                    }}>
                    <source src={afterMovie} type="video/mp4" />
                </video>

                <div style={{
                    width: '100%',
                    height: 'calc(100% - 100px)',
                    position: 'fixed',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    padding: '20px',
                    zIndex: '20',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Text className='js-emotion-presents' sx={{
                        fontSize: [3, 4, 4, 4, 4, 5],
                        fontWeight: '700',
                        display: 'flex',
                        flexDirection: 'row',
                        color: base.colors.yellow,
                    }}>
                        <Link sx={{
                            display: 'block',
                            textDecoration: 'none',
                            color: base.colors.yellow,
                        }} variant={'caps'} target="_blank"
                            href="https://emotion.nl/">EMOTION</Link>
                        <span>&nbsp;presents</span>
                    </Text>

                    <Box sx={{ transform: ['scale(1)', 'scale(0.8)', 'scale(0.8)', 'scale(0.9)', 'scale(0.9)', 'scale(1)'] }}>
                        <Image className='js-mixed-logo' sx={{
                            padding: '15px 0 15px'
                        }} src={logo} />
                    </Box>
                    <Text className='js-mixed-date' sx={{
                        fontSize: [3, 5, 5, 5, 5, 6],
                        fontWeight: '700',
                        borderBottom: '4px solid ' + base.colors.yellow,
                        color: base.colors.yellow,
                        textTransform: 'uppercase',
                    }}>
                        29 september 2022
                    </Text>
                </div>
            </Box>
        </Box>
    );
}
