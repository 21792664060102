import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import './reset.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const Application = () => {
    return (
        <App />
    )
}

root.render(<Application />,)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
