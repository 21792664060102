import './App.css';
import { ThemeProvider } from 'theme-ui';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AuthContext } from './context/auth';
import { CookiesProvider, useCookies } from 'react-cookie';
import process from 'process';
import { Buffer } from 'buffer';
import EventEmitter from 'events';
import theme from './theme';

import Home from './pages/Home';

window.process = process;
window.Buffer = Buffer;
window.EventEmitter = EventEmitter;

const tokenKey = 'token';

function App() {
    const [cookies, removeCookie] = useCookies([tokenKey])

    return (
        <CookiesProvider>
            <AuthContext.Provider
                value={{
                    authToken: cookies[tokenKey],
                    signOut: () => removeCookie(tokenKey, ''),
                }}
            >
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </AuthContext.Provider>
        </CookiesProvider>
    )
}

export default App
